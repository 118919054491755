<script setup>
import { onMounted, ref, inject, computed } from "vue";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const backgroundColor = inject("backgroundColor");

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
  labels: {
    type: Array,
    default: () => [],
  },
});

const focusColor = "#31fff9";
const chartRef = ref(null);
const currentIndex = ref(0);
const selectedItem = computed(() => ({
  label: props.data.labels?.[currentIndex.value],
  value: props.data.data?.[currentIndex.value],
}));

onMounted(() => {
  Chart.overrides["doughnut"].plugins.legend.display = false;

  new Chart(chartRef.value, {
    type: "doughnut",
    data: {
      labels: props.data.labels,
      datasets: [
        {
          cutout: "64%",
          data: props.data.data,
          backgroundColor: [focusColor, ...backgroundColor.value],
          borderWidth: 0, // remove border
          datalabels: {
            color: "#ffffff",
            font: {
              size: 16,
            },
            formatter: (val, ctx) => {
              const i = ctx.dataIndex;
              return props.labels[i];
            },
          },
        },
      ],
    },
    options: {
      responsive: true,
      events: ["click"],
      legend: {
        display: false,
      },
      onClick: (e, legendItem, legend) => {
        const index = legendItem[0].index;
        currentIndex.value = index;
        legend.data.datasets[0].backgroundColor = backgroundColor.value.map(
          (s, i) => (i === index ? focusColor : s)
        );
        legend.update();
      },
    },
    plugins: props.labels.length ? [ChartDataLabels] : [],
  });
});
</script>

<template>
  <div class="canvas-wrapper">
    <div class="inner">
      <div class="info">
        <div class="portion">{{ `${selectedItem.value}%` }}</div>
        <div class="base">{{ selectedItem.label }}</div>
      </div>
      <canvas ref="chartRef"></canvas>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.canvas-wrapper {
  margin: 4% 10% 10% 10%;
  max-width: 450px;
}

.inner {
  position: relative;
}

.info {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  color: #31fff9;
  font-size: 24px;
  font-family: "Zpix", sans-serif, serif;
  text-align: center;

  @include RWD($mediumLaptop) {
    font-size: 18px;
  }

  @include RWD($phone) {
    font-size: 16px;
  }

  .portion {
    &::after {
      content: "";
      display: block;
      height: 1px;
      background-color: currentColor;
      margin: 8px 0;
    }
  }

  .base {
    font-size: 0.8em;
  }

  > * {
    font: inherit;
  }
}
</style>
