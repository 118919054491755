<script>
export default {
  inheritAttrs: false,
};
</script>
<script setup>
import { onMounted, ref, reactive, computed, inject } from "vue";
import NormalDonutChart from "./NormalDonutChart.vue";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const categoryChartRef = ref(null);

const backgroundColor = inject("backgroundColor");

const data = reactive([
  { label: "A", value: 100, name: "公共服務類建築" },
  { label: "B", value: 50, name: "文化類建築" },
  { label: "C", value: 77, name: "數位構築" },
  { label: "D", value: 20, name: "環境及生態環境" },
  { label: "E", value: 10, name: "照護及教育類建築" },
  { label: "F", value: 80, name: "改建再利用" },
  { label: "G", value: 10, name: "基礎建設工業及交通運輸" },
  { label: "H", value: 72, name: "休閒環境類空間" },
  { label: "I", value: 5, name: "區域/社區規劃" },
  { label: "J", value: 40, name: "住宅類建築" },
  { label: "K", value: 90, name: "商業類建築" },
  { label: "L", value: 20, name: "感受/精神場所語宗教" },
]);

let chart = null;
const current = ref(0);

const configBackgroundColor = computed(() => {
  return backgroundColor.value.map((color, index) => {
    if (index === current.value) {
      color = "#31fff9";
    }
    return color;
  });
});

const config = {
  datasets: [
    {
      cutout: "70%",
      data: data.map(({ value }) => value),
      backgroundColor: configBackgroundColor.value,
      borderWidth: 0, // remove border
      datalabels: {
        color: "#ffffff",
        font: {
          size: 16,
        },
        formatter: (val, ctx) => {
          const i = ctx.dataIndex;

          return ctx.dataset.labels[i]; // show A, B, C, D in chart
        },
      },
    },
  ],
};

onMounted(() => {
  chart = new Chart(categoryChartRef.value, {
    type: "doughnut",
    data: config,
    plugins: [ChartDataLabels],
  });
});

// const handlePrevious = () => {
//   if (current.value === 0) {
//     current.value = data.length - 1;
//   } else {
//     current.value--;
//   }
//   handleUpdateChartBackground();
// };

// const handleNext = () => {
//   if (current.value === data.length - 1) {
//     current.value = 0;
//   } else {
//     current.value++;
//   }
//   handleUpdateChartBackground();
// };

// const handleUpdateChartBackground = () => {
//   chart.data.datasets[0].backgroundColor = configBackgroundColor.value;
//   chart.update();
// };
</script>

<template>
  <div class="canvas-block">
    <NormalDonutChart v-bind="$attrs"></NormalDonutChart>
    <!-- <div class="canvas-wrapper">
      <div class="controller">
        <div class="text">{{ data[current].value }}%</div>
        <div class="line-block">
          <div class="left" @click="handlePrevious">
            <img src="@/images/icons/uptriangle-blue.svg" alt="left" />
          </div>
          <div class="line"></div>
          <div class="right" @click="handleNext">
            <img src="@/images/icons/uptriangle-blue.svg" alt="right" />
          </div>
        </div>
        <div class="category">{{ data[current].name }}</div>
      </div>

      <canvas ref="categoryChartRef" class="chart"></canvas>
    </div> -->

    <div class="category-lists">
      <div v-for="{ label, name } in data" :key="label" class="category-item">
        {{ label }} {{ name }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.canvas-block {
  margin: 4% 10% 10% 10%;
  max-width: 500px;

  @include RWD($mediumLaptop) {
    padding: 12px;
  }

  .category-lists {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(6, auto);
    grid-auto-columns: 1fr;
    gap: 0.5em;
    font-size: 18px;

    .category-item {
      font-family: "Zpix", sans-serif, serif;
      color: #ffffff;
    }

    @include RWD($mediumLaptop) {
      font-size: 16px;
    }

    @include RWD($phone) {
      font-size: 14px;
      grid-template-rows: initial;
      grid-auto-flow: row;
      grid-auto-columns: max-content;
      place-content: center;
    }
  }
}
</style>
