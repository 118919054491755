export default {
  subject: {
    title: "畢業設計分類佔比",
    labels: [
      "公共服務類建築",
      "文化類建築",
      "數位構築",
      "環境及生態建築",
      "照護及教育類建築",
      "改建再利用",
      "基礎建設工業及交通運輸",
      "休閒娛樂類空間",
      "區域/社區規劃",
      "住宅類建築",
      "商業類建築",
      "感受/精神場所與宗教空間"
    ],
    data: [6, 11, 2, 6, 7, 4, 11, 11, 17, 7, 4, 19]
  },
  service: {
    title: "服務人數",
    labels: ["1000人以上", "10-50人", "50-100人", "150-500人", "500-1000人"],
    data: [31.5, 13, 13, 19.5, 24]
  },
  budget: {
    title: "畢業設計製作所使用經費",
    labels: ["2萬-5萬", "5萬以上", "5000以下", "5000-1萬", "1萬-2萬"],
    data: [11, 7, 30, 26, 26]
  },
  dimension: {
    title: "基地大小",
    labels: ["10m以上", "500m以下", "500m-5000m", "1萬m-10m以上", "5000m-1萬m"],
    data: [20, 5, 22, 40, 13]
  },
  location: {
    title: "基地位置",
    labels: ["台北", "新北", "彰化", "嘉義", "高雄", "台東"],
    data: [56, 9, 2, 2, 2, 2]
  },
  purpose: {
    title: "3D建模主要目的",
    labels: ["模擬空間效果", "製作表現法圖面", "無建模", "參數化設計", "方案系統開發模擬實際模擬效果"],
    data: [69, 22, 2, 2, 5]
  },
  tools: {
    title: "設計發展的主要工具",
    labels: ["3D軟體", "參數化自動生成軟體", "手繪", "製作概念模"],
    data: [72, 4, 15, 9]
  }
}