<script>
export default {
  name: "MapIndex",
};
</script>

<script setup>
import {
  ref,
  reactive,
  provide,
  inject,
  watch,
  onMounted,
  computed,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Loader } from "google-maps";
import CategoryChart from "./components/CategoryChart.vue";
import NormalDonutChart from "./components/NormalDonutChart.vue";
import DoubleDonutChart from "./components/DoubleDonutChart.vue";
import BarChart from "./components/BarChart.vue";
import ChartTitle from "./components/ChartTitle.vue";
import Works from "./components/Works.vue";
import studentData from "./utils/student-data";
import chartData from "./utils/chartData";

const router = useRouter();
const mapRef = ref(null);
const map = ref(null);
const markers = reactive([]);

const loader = new Loader("AIzaSyCOETtvu30PlmeMdPYSWg0unLoyrYJzLt8");
let google = null;

const currentWork = ref(1);

const store = useStore();

const alphabetLabels = computed(() => {
  return chartData.subject.data.map((item, i) => String.fromCharCode(65 + i));
});
const portfolios = computed(() => {
  return store.getters.portfolios;
});

const configWorks = computed(() => {
  return studentData.map((data) => {
    const d = portfolios.value.find(({ name }) => name === data.work);

    if (d) {
      return { ...data, work_id: d.work_id };
    }

    return data;
  });
});

onMounted(async () => {
  google = await loader.load();
  store.commit("setLoading", false);

  document.addEventListener("portfolio", ({ detail }) => {
    router.push(`/portfolios/${detail}`);
  });

  map.value = new google.maps.Map(mapRef.value, {
    center: { lat: 24.8, lng: 121.4 },
    zoom: 10,
    styles: [
      {
        featureType: "water",
        stylers: [{ color: "#173581" }],
      },
      {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [{ color: "#c8c8c8" }],
      },
      {
        featureType: "landscape.natural",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "landscape.natural.terrain",
        elementType: "geometry",
        stylers: [{ color: "#173581" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#464646" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#c8c8c8" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#c8c8c8" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#dddddd" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.icon",
        stylers: [{ saturation: -100 }],
      },
      {
        featureType: "transit.line",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
    ],
  });

  const square = {
    path: "M -2,-2 2,-2 2,2 -2,2 z", // 'M -2,0 0,-2 2,0 0,2 z',
    strokeColor: "#31fff9",
    fillColor: "#31fff9",
    fillOpacity: 1,
    scale: 5,
  };

  configWorks.value.forEach(({ position, id, work_id, work }) => {
    const marker = new google.maps.Marker({
      position,
      map: map.value,
      label: {
        text: id.toString(),
        color: "#000000",
      },
      icon: square,
    });

    // TODO::Need to add link to goto the work
    const infoWindow = new google.maps.InfoWindow({
      content: `
        <div>
          <a href="#" onclick="document.dispatchEvent(new CustomEvent('portfolio', { detail: ${work_id} })); return false;">${work}</a>
        </div>
      `,
    });

    marker.addListener("click", () => {
      map.value.panTo(position);

      infoWindow.open(map.value, marker);
    });

    markers.push({ marker, infoWindow });
  });
});

const handleFindMarker = (id) => {
  currentWork.value = id;

  const result = studentData[id - 1];

  map.value.panTo(result.position);
};

const current = ref("work");

const backgroundColor = ref([
  "#a9a9a9",
  "#757575",
  "#c5c5c5",
  "#a9a9a9",
  "#757575",
  "#8e8e8e",
  "#434343",
  "#8e8e8e",
  "#434343",
  "#757575",
  "#5b5b5b",
  "#434343",
]);

const media = inject("media");

// make sure first is work
watch(
  () => media.value,
  () => {
    current.value = "work";
  }
);

provide("backgroundColor", backgroundColor);

const fakeData = ref([
  { label: "A", value: 100, name: "公共服務類建築" },
  { label: "B", value: 50, name: "文化類建築" },
  { label: "C", value: 77, name: "數位構築" },
  { label: "D", value: 20, name: "環境及生態環境" },
  { label: "E", value: 10, name: "照護及教育類建築" },
  { label: "F", value: 80, name: "改建再利用" },
  { label: "G", value: 10, name: "基礎建設工業及交通運輸" },
  { label: "H", value: 72, name: "休閒環境類空間" },
  { label: "I", value: 5, name: "區域/社區規劃" },
  { label: "J", value: 40, name: "住宅類建築" },
  { label: "K", value: 90, name: "商業類建築" },
  { label: "L", value: 20, name: "感受/精神場所語宗教" },
]);

const tools = ref([
  { name: "VR", value: 2 },
  { name: "3D列印", value: 36 },
  { name: "雷切", value: 30 },
  { name: "保麗龍切割機", value: 4 },
  { name: "樂高", value: 1 },
]);

const interiorSoftware = ref([
  { name: "Grasshopper", value: 9 },
  { name: "AutoCAD列印", value: 36 },
  { name: "Rhino", value: 15 },
  { name: "Revit", value: 32 },
  { name: "Lumnion", value: 29 },
  { name: "Twinmontion", value: 6 },
  { name: "SketchUp", value: 35 },
  { name: "Escape", value: 2 },
  { name: "Vray", value: 8 },
  { name: "Ps", value: 1 },
]);

const graphicSoftware = ref([
  { name: "Photoshop", value: 53 },
  { name: "Illustrator", value: 42 },
  { name: "PowerPoint", value: 39 },
  { name: "AfterEffect", value: 1 },
  { name: "Indesign", value: 2 },
  { name: "威力導演", value: 1 },
  { name: "Procreate", value: 1 },
]);
</script>

<template>
  <div class="map-index-page">
    <div class="detail">
      <h3 class="page-title">MAP INDEX</h3>

      <div class="content">
        <!-- google map part  -->
        <div
          class="map"
          ref="mapRef"
          v-show="
            current === 'work' || (media === 'desktop' && current === 'static')
          "
        ></div>

        <!--  Desktop block  -->
        <div class="desktop-info" v-if="media === 'desktop'">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  :class="current === 'work' ? 'active' : ''"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="current !== 'work' ? '#collapseOne' : ''"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                  @click="current = 'work'"
                >
                  <span class="sub-title"
                    >WORK INDEX <span>作品總覽</span></span
                  >
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <Works
                    :works="studentData"
                    :current="currentWork"
                    @findMarker="handleFindMarker"
                  ></Works>
                </div>
              </div>
            </div>
            <div
              class="accordion-item"
              v-show="
                media === 'desktop' ||
                (media === 'mobile' && current === 'static')
              "
            >
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  :class="current === 'static' ? 'active' : ''"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="current !== 'static' ? '#collapseTwo' : ''"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  @click="current = 'static'"
                >
                  <span class="sub-title"
                    >STATIC CHART <span>統計圖表</span></span
                  >
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ChartTitle
                    title="CATEGORY"
                    subtitle="畢業設計分類佔比"
                  ></ChartTitle>
                  <CategoryChart
                    :data="chartData.subject"
                    :labels="alphabetLabels"
                  ></CategoryChart>

                  <ChartTitle
                    title="SERVING"
                    subtitle="服務人數佔比"
                  ></ChartTitle>
                  <NormalDonutChart
                    :data="chartData.service"
                  ></NormalDonutChart>

                  <ChartTitle
                    title="SPENDING"
                    subtitle="畢業製作所使用經費"
                  ></ChartTitle>
                  <NormalDonutChart :data="chartData.budget"></NormalDonutChart>

                  <ChartTitle
                    title="SPACING & AREA"
                    subtitle="基地大小 & 位置"
                  ></ChartTitle>
                  <DoubleDonutChart
                    :data="[chartData.dimension, chartData.location]"
                  ></DoubleDonutChart>

                  <ChartTitle
                    title="3D RENDERING"
                    subtitle="3D建模主要目的"
                  ></ChartTitle>
                  <NormalDonutChart
                    :data="chartData.purpose"
                  ></NormalDonutChart>

                  <ChartTitle
                    title="DESIGN DEVELOPING"
                    subtitle="設計發展的主要工具"
                  ></ChartTitle>
                  <NormalDonutChart :data="chartData.tools"></NormalDonutChart>

                  <ChartTitle
                    title="TOOLS"
                    subtitle="製作輔助工具"
                  ></ChartTitle>
                  <BarChart :data="tools"></BarChart>

                  <ChartTitle
                    title="INTERIOR SOFTWARE"
                    subtitle="空間繪圖軟體"
                  ></ChartTitle>
                  <BarChart :data="interiorSoftware"></BarChart>

                  <ChartTitle
                    title="GRAPHIC SOFTWARE"
                    subtitle="平面繪圖軟體"
                  ></ChartTitle>
                  <BarChart :data="graphicSoftware"></BarChart>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  mobile part  -->
        <div class="mobile-info" v-else>
          <button
            class="trigger-button"
            v-if="current === 'work'"
            @click="current = 'static'"
          >
            <span class="sub-title">WORK INDEX <span>作品總覽</span></span>

            <span class="arrow-button">
              <span></span>
            </span>
          </button>
          <button
            class="trigger-button reverse"
            v-else
            @click="current = 'work'"
          >
            <span class="sub-title">STATIC CHART <span>統計圖表</span></span>

            <span class="arrow-button">
              <span></span>
            </span>
          </button>

          <div class="works" v-show="current === 'work'">
            <Works
              :works="studentData"
              :current="currentWork"
              @findMarker="handleFindMarker"
            ></Works>
          </div>

          <div class="static" v-show="current === 'static'">
            <ChartTitle
              title="CATEGORY"
              subtitle="畢業設計分類佔比"
            ></ChartTitle>
            <CategoryChart></CategoryChart>

            <ChartTitle title="SERVING" subtitle="服務人數佔比"></ChartTitle>
            <NormalDonutChart :data="chartData.service"></NormalDonutChart>

            <ChartTitle
              title="SPENDING"
              subtitle="畢業製作所使用經費"
            ></ChartTitle>
            <NormalDonutChart :data="chartData.budget"></NormalDonutChart>

            <ChartTitle
              title="SPACING & AREA"
              subtitle="基地大小 & 位置"
            ></ChartTitle>
            <DoubleDonutChart
              :space="fakeData"
              :location="fakeData"
              :data="[chartData.dimension, chartData.location]"
            ></DoubleDonutChart>

            <ChartTitle
              title="3D RENDERING"
              subtitle="3D建模主要目的"
            ></ChartTitle>
            <NormalDonutChart :data="chartData.purpose"></NormalDonutChart>

            <ChartTitle
              title="DESIGN DEVELOPING"
              subtitle="設計發展的主要工具"
            ></ChartTitle>
            <NormalDonutChart :data="chartData.tools"></NormalDonutChart>

            <ChartTitle title="TOOLS" subtitle="製作輔助工具"></ChartTitle>
            <BarChart :data="tools"></BarChart>

            <ChartTitle
              title="INTERIOR SOFTWARE"
              subtitle="空間繪圖軟體"
            ></ChartTitle>
            <BarChart :data="interiorSoftware"></BarChart>

            <ChartTitle
              title="GRAPHIC SOFTWARE"
              subtitle="平面繪圖軟體"
            ></ChartTitle>
            <BarChart :data="graphicSoftware"></BarChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.map-index-page {
  @include page;

  .page-title {
    @include page-title($white);
  }

  .content {
    padding-top: 4%;
    display: flex;
    flex-wrap: wrap;
  }

  .map {
    width: 60%;
    border: 0.01rem solid #ffffff;

    @include RWD($tablet) {
      width: 100%;
      height: 600px;
    }

    @include RWD($phone) {
      height: 400px;
    }
  }

  .desktop-info {
    width: 40%;
    border: 0.01rem solid #ffffff;
    color: #ffffff;
    max-height: 900px;
    overflow: auto;

    @include RWD($tablet) {
      width: 100%;
    }

    .sub-title {
      font-size: 32px;
      font-family: "Red Rose", sans-serif, serif;
      letter-spacing: 4px;
      display: block;
      color: #ffffff;
      padding-left: 48px;

      span {
        font-size: 16px;
      }

      @include RWD($smallLaptop) {
        font-size: 24px;
      }

      @include RWD($tablet) {
        padding-left: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      @include RWD($phone) {
        font-size: 20px;

        span {
          font-size: 14px;
        }
      }
    }

    .accordion-button {
      border-bottom: 1px solid #ffffff;
      border-top: 1px solid #ffffff;
      position: relative;
      background-color: #000000;

      &.active {
        background-color: #434343;
      }

      &::after {
        margin-left: auto;
        position: absolute;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
      }

      &:focus,
      &:active {
        outline: none;
      }

      @include RWD($tablet) {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        &::after {
          display: none;
        }

        .arrow-button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .accordion-collapse {
      max-height: calc(760px);
      overflow-y: auto;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .accordion-item {
      background-color: #000000;
    }

    .accordion-body {
      padding: 0;
    }
  }

  .mobile-info {
    width: 100%;

    .trigger-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #ffffff;
      border-top: none;
      width: 100%;
      background-color: #434343;

      &.reverse {
        border-top: 1px solid #ffffff;
        flex-direction: row-reverse;

        .arrow-button {
          border-left: none;
          border-right: 1px solid #ffffff;

          span {
            transform: rotate(-90deg);
          }
        }
      }

      .sub-title {
        font-family: "Red Rose", sans-serif, serif;
        letter-spacing: 4px;
        display: block;
        color: #ffffff;
        padding: 12px;
      }

      .arrow-button {
        display: block;
        width: 100px;
        border-left: 1px solid #ffffff;
        background: #000000;

        span {
          display: block;
          width: 100%;
          height: 49px;
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
          background-size: 24px;
          background-position: center;
          background-repeat: no-repeat;
          transform: rotate(90deg);
        }
      }
    }

    .works {
      max-height: 800px;
      overflow: auto;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
</style>
