<script setup>
defineProps({
  works: {
    required: true,
    type: Array,
    default: [],
  },
  current: {
    required: true,
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(["findMarker"]);

const handleFindMarker = (id) => {
  emit("findMarker", id);
};
</script>

<template>
  <ul class="work-group">
    <li
      class="work-item"
      v-for="{ name_zh, name_en, work, id } in works"
      :key="id"
      @click="handleFindMarker(id)"
    >
      <div class="index" :class="current === id ? 'active' : ''">{{ id }}</div>
      <div class="person-info">
        <div class="title-info">
          <span>{{ work }}</span> {{ " " }}
        </div>
        <div class="name-info">
          <span>{{ name_zh }}</span> {{ " " }}
          <span v-if="name_zh !== name_en">{{ name_en }}</span>
        </div>
      </div>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.work-group {
  padding: 0;
  list-style: none;
  color: #ffffff;
}

.work-item {
  display: flex;
  padding: 24px 30% 24px 24px;
  background-color: #000000;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #222222;
  }

  .index {
    margin-top: 12px;
    margin-right: 24px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 1px solid #ffffff;

    &.active {
      background-color: #31fff9;
    }
  }

  .title-info {
    margin-bottom: 12px;

    span {
      font-size: 20px;
    }
  }

  .name-info {
    span {
      font-family: "Zpix", sans-serif, serif;
      opacity: 0.7;
    }
  }

  @include RWD($mediumLaptop) {
    padding: 24px;
  }

  @include RWD($tablet) {
    .title-info {
      margin-bottom: 4px;
      span {
        font-size: 16px;
      }
    }
    .name-info {
      span {
        font-size: 14px;
      }
    }
  }
}
</style>
