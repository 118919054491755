<script setup>
import { inject, computed } from "vue";

const backgroundColor = inject("backgroundColor");

const props = defineProps({
  data: {
    required: true,
    default: [],
    type: Array,
  },
});

const bigDataValue = computed(() => {
  let maxVal = 0;
  props.data.forEach(({ value }) => {
    if (value > maxVal) {
      maxVal = value;
    }
  });
  return maxVal;
});
</script>

<template>
  <div class="bar-chart-block">
    <div v-for="({ name, value }, index) in data" :key="index" class="bar-item">
      <div class="info">
        <div class="name">{{ name }}</div>
        <div class="value">{{ value }}%</div>
      </div>
      <div
        :style="{
          backgroundColor:
            value !== bigDataValue ? backgroundColor[index] : '#31fff9',
          width: value + '%',
        }"
        class="bar"
      ></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bar-chart-block {
  margin: 4% 5% 10% 10%;
  max-width: 500px;

  .bar-item {
    display: flex;

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 160px;
      margin-right: 12px;
    }

    .name {
      color: #ffffff;
    }

    .value {
      color: #31fff9;
    }

    .bar {
      height: 48px;
    }
  }
}
</style>
