export default [
  {
    id: 1,
    slug: "lee-yu-wei",
    name_zh: "李有為",
    name_en: "Lee, Yu-Wei",
    advisor: "李大椿",
    address: "駁二大義倉庫旁停車場",
    keywords: "博物館建築／拆船業／五金街",
    motto: "投資報酬率",
    ingredient: "80%拖延症+10%抱怨+5%絕望+5%終於乖乖做設計",
    thought: "做設計要有cp值的概念 ： )",
    address_coord: "22°37'09.9\"N 120°17'09.7\"E",
    position: {
      lat: 22.619417,
      lng: 120.286028,
    },
    work: "古今舶物：海事工業博物館",
    avatar: "A_LeeYuWei_00",
  },
  {
    id: 2,
    slug: "tu-chia-cheng",
    name_zh: "凃家誠",
    name_en: "Tu, Chia-Cheng",
    advisor: "許伯元",
    address: "嘉義市西區番社里",
    keywords: "橋下空間／都市沙漠／站區規劃",
    motto: "掙扎",
    ingredient: "100%的自我矛盾",
    thought: "一段既是尋找起點，也是尋找終點的旅程",
    address_coord: "23°28'45.8\"N 120°26'29.1\"E",
    position: {
      lat: 23.479389,
      lng: 120.441417,
    },
    work: "橋下交集–以嘉義車站為例重新構思橋下空間的可能型態",
    avatar: "A_TUChiaCheng_00",
  },
  {
    id: 3,
    slug: "kung-chao-chun",
    name_zh: "龔照峻",
    name_en: "Kung, Chao-Chun",
    advisor: "李金威",
    address: "台北市士林區至善路二段221號",
    keywords: "故宮／博物館／可見式典藏庫／數據化／自動化倉儲",
    motto: "睡飽很重要",
    ingredient: "30%睡覺+30%耍廢+30%認真做事",
    thought: "半年就夠了",
    address_coord: "25°06'03.3\"N 121°32'57.1\"E",
    position: {
      lat: 25.100917,
      lng: 121.549194,
    },
    work: "未來博物館：以大數據和人工智能位架構的新型態博物館—以台北故宮為例",
    avatar: "A_KUNGChaoChun_00",
  },
  {
    id: 4,
    slug: "wang-chun-wen",
    name_zh: "王俊文",
    name_en: "Wang, Chun-Wen",
    advisor: "林志崧",
    address: "彰化縣彰化市",
    keywords: "複合型開發／機械／都市環境",
    motto: "自省",
    ingredient: "奇幻",
    thought: "無盡的思考",
    address_coord: "24°04'53.3\"N 120°32'18.9\"E",
    position: {
      lat: 24.081472,
      lng: 120.538583,
    },
    work: "機械時代",
    avatar: "B_WANGChunWen_00",
  },
  {
    id: 5,
    slug: "鄭欽豪",
    name_zh: "鄭欽豪",
    name_en: "鄭欽豪",
    advisor: "邱韻祥",
    address: "台東關山崁頂部落",
    keywords: "布農／竹／木／實構築",
    motto: "行動力",
    ingredient: "快樂 開心",
    thought: "解決不了問，那就解決發現問題的人",
    address_coord: "23°02'43.7\"N 121°08'49.3\"E",
    position: {
      lat: 23.045472,
      lng: 121.147028,
    },
    work: "律動-以實構築介入布農生活文化",
    avatar: "B_CHENGChinHao_00",
  },
  {
    id: 6,
    slug: "馬德萱",
    name_zh: "馬德萱",
    name_en: "馬德萱",
    advisor: "邱韻祥",
    address: "關山親水公園",
    keywords: "阿美族／文化教育／親水／關山",
    motto: "投入",
    ingredient: "浪漫的腦+口水",
    thought: "文化即是生活。",
    address_coord: "23°02'22.8\"N 121°10'25.9\"E",
    position: {
      lat: 23.039667,
      lng: 121.173861,
    },
    work: "阿美族文化教育空間",
    avatar: "B_MADeSyuan_00",
  },
  {
    id: 7,
    slug: "林子嵃",
    name_zh: "林子嵃",
    name_en: "林子嵃",
    advisor: "邱韻祥",
    address: "台東縣關山鎭親水公園",
    keywords: "布農族／阿美族／客家民族／紀錄文化",
    motto: "如何引領別人進入設計",
    ingredient: "90%睡眠 10%咖啡因",
    thought: "地域產生了民族，民族產生了文化",
    address_coord: "23°02'22.8\"N 121°10'25.9\"E",
    position: {
      lat: 23.039667,
      lng: 121.173861,
    },
    work: "民族圖書館",
    avatar: "B_LINZiYan_00",
  },
  {
    id: 8,
    slug: "呂胤賢",
    name_zh: "呂胤賢",
    name_en: "呂胤賢",
    advisor: "盧俊廷",
    address: "宜蘭縣蘇澳鎮南方澳漁港",
    keywords: "生活產業平衡／南方澳地域／蘇南規劃案",
    motto: "如何定義什麼是好的建築",
    ingredient: "一點時間、一點精神、多一點腦",
    thought: "建築應該是平民式的，而非英雄式的",
    address_coord: "24°35'04.3\"N 121°51'54.4\"E",
    position: {
      lat: 24.584528,
      lng: 121.865111,
    },
    work: "未來舞台。南方澳",
    avatar: "C_LUYinHsien_00",
  },
  {
    id: 9,
    slug: "陸皓昀",
    name_zh: "陸皓昀",
    name_en: "陸皓昀",
    advisor: "李大椿",
    address: "苗栗縣通霄鎮新埔里",
    keywords: "聚落規劃/ 城市意象/ 鄰里單元",
    motto: "學習和社會關懷",
    ingredient: "愛情一大半，設計和嗜好占了剩下一半",
    thought: "挑戰會失敗，而我沒有成功。",
    address_coord: "24°32'43.6\"N 120°41'52.0\"E",
    position: {
      lat: 24.545444,
      lng: 120.697778,
    },
    work: "斜海弄－中部西海岸新埔聚落規劃",
    avatar: "C_LUHaoYun_00",
  },
  {
    id: 10,
    slug: "郭佩昀",
    name_zh: "郭佩昀",
    name_en: "郭佩昀",
    advisor: "張建翔",
    address: "琉球嶼",
    keywords: "第六次物種大滅絕／諾亞方舟",
    motto: "有過就好,我只做覺得開心的><",
    ingredient: "糖分,油脂,美貌：D",
    thought: "熬夜又吃宵夜，瘦不下來很OK吧：)",
    address_coord: "22°20'25.5\"N 120°22'17.5\"E",
    position: {
      lat: 22.340417,
      lng: 120.371528,
    },
    work: "群落生境 - 未來物種復育中心",
    avatar: "D_GUOPeiYun_00",
  },
  {
    id: 11,
    slug: "林憶儒",
    name_zh: "林憶儒",
    name_en: "林憶儒",
    advisor: "陳世堂",
    address: "彰化縣海埔里",
    keywords: "Industry ／ Living",
    motto: "習慣",
    ingredient: "拿邏輯當武器，自己躲在後面",
    thought: "既然都愛了，那就要更愛！",
    address_coord: "24°04'59.9\"N 120°25'17.2\"E",
    position: {
      lat: 24.083306,
      lng: 120.421444,
    },
    work: "海埔塭寮",
    avatar: "D_LINYiRu_00",
  },
  {
    id: 12,
    slug: "張祐齊",
    name_zh: "張祐齊",
    name_en: "張祐齊",
    advisor: "張純德",
    address: "澎湖縣內垵北漁港",
    keywords: "海底隧道／舊漁港改建／珊瑚復育",
    motto: "咖啡是我的生命泉源",
    ingredient: "50%美貌 50%才智",
    thought: "咖啡是我的生命泉源",
    address_coord: "23°34'30.4\"N 119°29'59.5\"E",
    position: {
      lat: 23.575111,
      lng: 119.499861,
    },
    work: "尋找尼莫",
    avatar: "D_CHANGUiiChi_00",
  },
  {
    id: 13,
    slug: "方敬能",
    name_zh: "方敬能",
    name_en: "方敬能",
    advisor: "李大椿",
    address: "新北市蘆洲區北部",
    keywords: "平疫結合/ 裝配式應急醫院",
    motto: "這個建得出來嗎...?",
    ingredient: "蠻牛，咖啡，綠茶，音樂",
    thought: "日間人類，夜間動物",
    address_coord: "25°05'23.2\"N 121°27'46.7\"E",
    position: {
      lat: 25.089778,
      lng: 121.462972,
    },
    work: "蘆水之隔",
    avatar: "E_FANGJingNeng_00",
  },
  {
    id: 14,
    slug: "紀可薆",
    name_zh: "紀可薆",
    name_en: "紀可薆",
    advisor: "戴嘉惠",
    address: "台北市文山區",
    keywords: "實驗教育／自然／兒童發展",
    motto: "要吃到薯條",
    ingredient: "50%薯條+200%幻覺",
    thought: "在T3吃著鹹酥雞，卻被病毒趕走QQ",
    address_coord: "24°59'16.8\"N 121°33'01.3\"E",
    position: {
      lat: 24.988,
      lng: 121.550361,
    },
    work: "都市中的華德福學校",
    avatar: "E_JIHKeAi_00",
  },
  {
    id: 15,
    slug: "林佳儀",
    name_zh: "林佳儀",
    name_en: "林佳儀",
    advisor: "林志崧",
    address: "南港舊庄國小",
    keywords: "School ／ Sustainability",
    motto: "感覺來了才做,其他時間都是看電腦發呆",
    ingredient: "想睡覺的因子",
    thought: "終於說掰掰了",
    address_coord: "25°02'24.0\"N 121°37'09.7\"E",
    position: {
      lat: 25.04,
      lng: 121.619361,
    },
    work: "植‧實驗場域",
    avatar: "E_LINChiaYi_00",
  },
  {
    id: 16,
    slug: "黃忻",
    name_zh: "黃忻",
    name_en: "黃忻",
    advisor: "張建翔",
    address: "華山文創園區",
    keywords: "圖書館／表演空間／鐵道",
    motto: "做的開心吃得健康睡的安穩",
    ingredient: "水、音樂、零食",
    thought: "一個讓人又笑又哭又氣的過程",
    address_coord: "25°02'38.9\"N 121°31'45.3\"E",
    position: {
      lat: 25.044139,
      lng: 121.52925,
    },
    work: "動靜之間",
    avatar: "E_ HUANGHsin_00",
  },
  {
    id: 17,
    slug: "王芷茜",
    name_zh: "王芷茜",
    name_en: "王芷茜",
    advisor: "許伯元",
    address: "台北市大稻埕 永樂國小",
    keywords: "Flexible Space / Historical Building",
    motto: "議題深度 圖面美觀 細部技術",
    ingredient:
      "倒入1/3哲學的腦，兩勺果斷、一匙愛美的心，充分混和後靜置。\n放入古道熱腸的烤箱，烘焙至表面稍稍老臣。\n在上菜前，記得點綴一紅一白的小花，\n即可得到一份「王芷茜」—\n初見成熟理性，細嚼後可得柔軟。",
    thought:
      "原本想念哲學而誤入建築的同學， 一場與伯元的思辨之旅， 我的腦覺得很幸福。",
    address_coord: "25°03'40.8\"N 121°30'38.4\"E",
    position: {
      lat: 25.061333,
      lng: 121.510667,
    },
    work: "疫情下永樂國小改建",
    avatar: "F_WANGChihChien_00",
  },
  {
    id: 18,
    slug: "徐子傑",
    name_zh: "徐子傑",
    name_en: "徐子傑",
    advisor: "盧俊廷",
    address: "建國啤酒廠",
    keywords: "古蹟／觀光／酒",
    motto: "晚點再想 skrskr",
    ingredient: "20%酒精",
    thought: "有點9",
    address_coord: "25°02'45.4\"N 121°32'08.9\"E",
    position: {
      lat: 25.045944,
      lng: 121.535806,
    },
    work: "建國啤酒廠",
    avatar: "F_HSUTsuChieh_00",
  },
  {
    id: 19,
    slug: "陳志綸",
    name_zh: "陳志綸",
    name_en: "陳志綸",
    advisor: "李金威",
    address: "草山前山",
    keywords: "戰爭／防空洞／浴場",
    motto: "誰敢說我設計可愛,直接開扁",
    ingredient: "一點懶 一點廢 一點衝動",
    thought: "Do what you want to do",
    address_coord: "25°09'05.2\"N 121°32'44.1\"E",
    position: {
      lat: 25.151444,
      lng: 121.545583,
    },
    work: "空襲警報",
    avatar: "F_CHENJyhLuen_00",
  },
  {
    id: 20,
    slug: "施景文",
    name_zh: "施景文",
    name_en: "施景文",
    advisor: "楊之瑾",
    address: "北京通州",
    keywords: "垂直起降／未來交通",
    motto: "參數化設計與數據分析",
    ingredient: "100%純理工男，程序猿一枚",
    thought: "科技改變生活",
    address_coord: "39°49'40.0\"N 116°47'01.6\"E",
    position: {
      lat: 39.827778,
      lng: 116.783778,
    },
    work: "北京空港",
    avatar: "G_SHIJingWen_00",
  },
  {
    id: 21,
    slug: "周家霈",
    name_zh: "周家霈",
    name_en: "周家霈",
    advisor: "楊之瑾",
    address: "台灣桃園國際機場",
    keywords: "Reconnect ／ Overcome the Epidemic",
    motto:
      "對於設計，我在意的點很多耶，有時候會被老師說是不是精神分裂甚麼都想做",
    ingredient: "70%水+其他的一些肌肉和骨頭之類的(請醫學系的朋友們回答)",
    thought: "下個太陽升起之時，世界連結將會重啟",
    address_coord: "25°04'44.1\"N 121°14'03.8\"E",
    position: {
      lat: 25.078917,
      lng: 121.234389,
    },
    work: "疫後重生 - 疫情之後，未來機場設計藍圖",
    avatar: "G_CHOUChiaPei_00",
  },
  {
    id: 22,
    slug: "李沛駿",
    name_zh: "李沛駿",
    name_en: "李沛駿",
    advisor: "楊之瑾",
    address: "台北市  玉泉公園 / 捷運北門站",
    keywords: "交通建築／都市規劃／垂直起降",
    motto: "做自己開心喜歡的最重要",
    ingredient: "懶惰 情緒化 感性",
    thought: "發掘自我的建築態度",
    address_coord: "25°02'59.1\"N 121°30'25.7\"E",
    position: {
      lat: 25.04975,
      lng: 121.507139,
    },
    work: "啟動台北的流動性",
    avatar: "G_LEEPeiChun_00",
  },
  {
    id: 23,
    slug: "周勤超",
    name_zh: "周勤超",
    name_en: "周勤超",
    advisor: "蘇重威",
    address: "台北松山機場, 信義計畫區",
    keywords: "Air Taxi ／ Modular",
    motto: "COBE有沒有更新",
    ingredient: "COBE & BIG",
    thought: "收穫滿滿的一年",
    address_coord: "25°03'48.7\"N 121°33'06.9\"E",
    position: {
      lat: 25.063528,
      lng: 121.551917,
    },
    work: "2048台北空港系統",
    avatar: "G_ZHOUQinChao_00",
  },
  {
    id: 24,
    slug: "何奕賢",
    name_zh: "何奕賢",
    name_en: "何奕賢",
    advisor: "Tiago Costa",
    address: "新北市八里區",
    keywords: "F1／MotoGP／Circuit／EV",
    motto: "如何呈現有趣的效果",
    ingredient: "100% Marllboro",
    thought: "對於這個題目我覺得可以發揮的空間非常多，做得非常享受!",
    address_coord: "25°08'40.8\"N 121°23'22.1\"E",
    position: {
      lat: 25.144667,
      lng: 121.389472,
    },
    work: "台北賽車場",
    avatar: "H_HOYiHsien_00",
  },
  {
    id: 25,
    slug: "陳韋翰",
    name_zh: "陳韋翰",
    name_en: "陳韋翰",
    advisor: "Tiago Costa",
    address: "台北市西門町",
    keywords: "電影公園／後街文化／西門町",
    motto: "你的建築跟基地有甚麼關聯?",
    ingredient: "人家有的我都有，除了肝",
    thought: "終於熬過建築系",
    address_coord: "25°02'40.8\"N 121°30'11.5\"E",
    position: {
      lat: 25.044667,
      lng: 121.503194,
    },
    work: "西門町後街電影公園改造",
    avatar: "H_CHENWeiHan_00",
  },
  {
    id: 26,
    slug: "徐暄惠",
    name_zh: "徐暄惠",
    name_en: "徐暄惠",
    advisor: "莊熙平",
    address: "大稻埕碼頭",
    keywords: "水岸v.s 城市／活動",
    motto: "套用老師的一句話：為何而戰？",
    ingredient: "打橋牌阿各位!!!...(ง •ૅ౪•᷄)ว",
    thought: "將被隔絕在外的水岸重新與城市連接起來",
    address_coord: "25°03'23.0\"N 121°30'27.3\"E",
    position: {
      lat: 25.056389,
      lng: 121.507583,
    },
    work: "水岸再現-城市與水岸的對話",
    avatar: "H_HSUHsuanHuei_00",
  },
  {
    id: 27,
    slug: "蕭詠逸",
    name_zh: "蕭詠逸",
    name_en: "蕭詠逸",
    advisor: "張純德",
    address: "信義計劃區/香緹廣場",
    keywords: "音樂廳",
    motto: "自問自答",
    ingredient: "鋼琴曲、笑還有哭",
    thought: "思考自己是如何思考設計",
    address_coord: "25°02'14.4\"N 121°34'01.5\"E",
    position: {
      lat: 25.037333,
      lng: 121.567083,
    },
    work: "訪聲築調",
    avatar: "H_HSIAOYungI_00",
  },
  {
    id: 28,
    slug: "徐華均",
    name_zh: "徐華均",
    name_en: "徐華均",
    advisor: "許伯元",
    address: "正義新城 ( 空總北側&忠孝東路四段之間 )",
    keywords: "Vertical Village / Public Residence",
    motto: "邏輯合理 深度探討 解決問題 清楚表達",
    ingredient: "1/3肯定+1/3自我懷疑+1/3天馬行空",
    thought: "用「歷史」與「現代」思考「未來」",
    address_coord: "25°02'29.3\"N 121°32'19.6\"E",
    position: {
      lat: 25.041472,
      lng: 121.538778,
    },
    work: "以混合使用為設計原則的新形態公宅聚落",
    avatar: "I_HSUHuaChun_00",
  },
  {
    id: 29,
    slug: "凃玉涵",
    name_zh: "凃玉涵",
    name_en: "凃玉涵",
    advisor: "張純德",
    address: "大同區雙連里",
    keywords: "都市設計／商店圈／住商混和",
    motto: "邊看動畫邊做事",
    ingredient:
      "水30升，碳20千克，氨4升，石灰1.5千克，磷800克，鹽250克，硝石100克，硫磺80克，氟7.5克，鐵5克，矽3克，還有其餘15種微量元素",
    thought: "畢業設計的原則是等價交換。",
    address_coord: "25°03'33.3\"N 121°31'14.4\"E",
    position: {
      lat: 25.05925,
      lng: 121.520667,
    },
    work: "建築與街道的對話-高密度住商混合區空間與虛空間的可能",
    avatar: "I_TUYuHan_00",
  },
  {
    id: 30,
    slug: "蕭家宥",
    name_zh: "蕭家宥",
    name_en: "蕭家宥",
    advisor: "戴嘉惠",
    address: "台北市光復市場/錦安市場",
    keywords: "電腦輔助設計",
    motto: "那些我所失去的",
    ingredient: "濃縮暗物質的假配方",
    thought: "這不是人工智慧，但它讓我們理解自己",
    address_coord: "25°02'10.8\"N 121°33'37.9\"E",
    position: {
      lat: 25.036333,
      lng: 121.560528,
    },
    work: "生活的總集",
    avatar: "I_HSIAOChiaYu_00",
  },
  {
    id: 31,
    slug: "陳樂駿",
    name_zh: "陳樂駿",
    name_en: "陳樂駿",
    advisor: "盧俊廷",
    address: "香港將軍澳",
    keywords: "青銀共居／香港社會住宅／未來生活模式",
    motto: "設計所解決的問題和享受設計",
    ingredient: "爽",
    thought: "人生既然必經之路這麼流暢， 失敗一次有什麼所謂。 沒有撞板沒有刺激",
    address_coord: "22°18'23.7\"N 114°15'46.4\"E",
    position: {
      lat: 22.306583,
      lng: 114.262889,
    },
    work: "共融：香港社會住宅將來的可能性",
    avatar: "J_CHANNgokChun_00",
  },
  {
    id: 32,
    slug: "康闓",
    name_zh: "康闓",
    name_en: "康闓",
    advisor: "陳嘉芸",
    address: "台北市萬華區桂林路64號",
    keywords: "社會住宅／閒置空間／新+ 舊",
    motto: "實用性、可行性、真實性",
    ingredient: "睡到自然醒、做設計",
    thought: "近未來可行的住宅計畫",
    address_coord: "25°02'15.3\"N 121°30'09.6\"E",
    position: {
      lat: 25.037583,
      lng: 121.502667,
    },
    work: "心距離",
    avatar: "J_KANGKai_00",
  },
  {
    id: 33,
    slug: "林宥均",
    name_zh: "林宥均",
    name_en: "林宥均",
    advisor: "張純德",
    address: "台北市萬華區國光國宅",
    keywords: "青銀共居／社會住宅",
    motto: "睡飽飽才會效率好",
    ingredient: "床上的娃娃：小豬貢丸",
    thought: "睡飽＝開心做設計",
    address_coord: "25°01'30.3\"N 121°30'27.5\"E",
    position: {
      lat: 25.025083,
      lng: 121.507639,
    },
    work: "西城故事：國光社宅",
    avatar: "J_LINYuChun_00",
  },
  {
    id: 34,
    slug: "顏宇岑",
    name_zh: "顏宇岑",
    name_en: "顏宇岑",
    advisor: "陳世堂",
    address: "建國市場",
    keywords: "傳統市場／市場轉型／文創",
    motto: "開心，感覺",
    ingredient: "可甜可鹹",
    thought: "吃了好多，但腦細胞也死好多",
    address_coord: "24°58'29.2\"N 121°32'26.1\"E",
    position: {
      lat: 24.974778,
      lng: 121.540583,
    },
    work: "傳統市場的未來轉型",
    avatar: "K_YENYuTsen_00",
  },
  {
    id: 35,
    slug: "陳岳忻",
    name_zh: "陳岳忻",
    name_en: "陳岳忻",
    advisor: "許伯元",
    address: "台北松山區慈祐里",
    keywords: "複合型傳統市場／木建築",
    motto: "與其完美無缺，不如讓人有想像空間",
    ingredient: "TP6M,4VUP +厭世",
    thought: "JI3RU.4Q841O4A848 。",
    address_coord: "25°03'03.4\"N 121°34'14.1\"E",
    position: {
      lat: 25.050944,
      lng: 121.570583,
    },
    work: "南松複合性傳統市場",
    avatar: "K_CHENYuehHsin_00",
  },
  {
    id: 36,
    slug: "陳冠縈",
    name_zh: "陳冠縈",
    name_en: "陳冠縈",
    advisor: "陳嘉芸",
    address: "高雄市楠梓區",
    keywords: "廟宇／社區與都市／現代思維",
    motto: "運氣跟行動力",
    ingredient: "運動、睡眠跟發呆",
    thought: "下個世代的民間信仰該如何存在呢？",
    address_coord: "22°42'47.2\"N 120°17'08.8\"E",
    position: {
      lat: 22.713111,
      lng: 120.285778,
    },
    work: "信仰之下，百姓的生活地景",
    avatar: "L_CHENKuanYin_00",
  },
  {
    id: 37,
    slug: "鄭硯馨",
    name_zh: "鄭硯馨",
    name_en: "鄭硯馨",
    advisor: "郭英釗",
    address: "台南神學院/彌陀寺",
    keywords: "宗教集會堂／基督教／佛教",
    motto: "我快樂所以我快樂",
    ingredient: "一介花瓶",
    thought: "「硯馨，你對畢業設計的野心是什麼?」「畢業。」",
    address_coord: "22°59'18.5\"N 120°12'51.0\"E",
    position: {
      lat: 22.988472,
      lng: 120.214167,
    },
    work: "宗教空間複合使用",
    avatar: "L_CHENGYenHsing_00",
  },
  {
    id: 38,
    slug: "邱振圃",
    name_zh: "邱振圃",
    name_en: "邱振圃",
    advisor: "張純德",
    address: "台北赤峰街區",
    keywords: "澡堂／感知／水／儀式",
    motto: "我所想 我所做",
    ingredient: "60%生活20%設計20%睡覺",
    thought: "畢業設計只想做一次",
    address_coord: "25°03'14.6\"N 121°31'08.7\"E",
    position: {
      lat: 25.054056,
      lng: 121.519083,
    },
    work: "城市遇所",
    avatar: "L_CHIUChenPu_00",
  },
  {
    id: 39,
    slug: "謝時璿",
    name_zh: "謝時璿",
    name_en: "謝時璿",
    advisor: "蔡孟廷",
    address: "博愛特區",
    keywords: "時間／觀點／世界",
    motto: "所見，所知，所存在",
    ingredient: "34%世界",
    thought: "作為現象的直觀描述",
    address_coord: "25°02'23.1\"N 121°30'42.7\"E",
    position: {
      lat: 25.03975,
      lng: 121.511861,
    },
    work: "略稱：時間觀測所",
    avatar: "M_HSIEHShihHsuan_00",
  },
  {
    id: 40,
    slug: "江洋萱",
    name_zh: "江洋萱",
    name_en: "江洋萱",
    advisor: "莊熙平",
    address: "臺北車站",
    keywords: "N/A",
    motto: "不要披風 !",
    ingredient: "99%感性1%理性",
    thought: "1 vs. 99",
    address_coord: "25°02'49.2\"N 121°31'00.8\"E",
    position: {
      lat: 25.047,
      lng: 121.516889,
    },
    work: "無傷亡之抗爭 ： 建築蒙太奇",
    avatar: "M_JIANGYangXuan_00",
  },
  {
    id: 41,
    slug: "陳映竹",
    name_zh: "陳映竹",
    name_en: "陳映竹",
    advisor: "李金威",
    address: "台北島",
    keywords: "生命／死亡／記憶／存在",
    motto: "旁邊有沒有人跟我說話",
    ingredient: "感性的腦袋、超標的糖分、說不完的話",
    thought:
      "珍珠奶茶萬歲！      珍珠奶茶萬歲！           珍珠奶茶萬歲！                珍珠奶茶萬歲！",
    address_coord: "25°02'57.8\"N 121°30'02.9\"E",
    position: {
      lat: 25.049389,
      lng: 121.500806,
    },
    work: "存在之後",
    avatar: "M_CHENYingZhu_00",
  },
  {
    id: 42,
    slug: "朱瑾",
    name_zh: "朱瑾",
    name_en: "朱瑾",
    advisor: "蘇重威",
    address: "信義計畫區 D1",
    keywords: "網路成癮",
    motto: "自己開心最重要",
    ingredient: "100%網路成癮",
    thought: "笑 到 最 後 才 是 贏 家",
    address_coord: "25°01'56.9\"N 121°33'42.7\"E",
    position: {
      lat: 25.032472,
      lng: 121.561861,
    },
    work: "當代庇護所",
    avatar: "M_CHUHeidi_00",
  },
  {
    id: 43,
    slug: "羅梓鋒",
    name_zh: "羅梓鋒",
    name_en: "羅梓鋒",
    advisor: "蘇重威",
    address: "馬來西亞吉隆坡舊城區",
    keywords: "文化地景／文化與創意產業",
    motto: "基地真的需要我的設計嗎？",
    ingredient: "咖啡，一度贊－爌肉麵再加點麻辣花生",
    thought: "難產",
    address_coord: "3°08'41.5\"N 101°41'43.3\"E",
    position: {
      lat: 3.144861,
      lng: 101.695361,
    },
    work: "KLOT之反抗",
    avatar: "N_LOHJheeFung_00",
  },
  {
    id: 44,
    slug: "蔡沛淇",
    name_zh: "蔡沛淇",
    name_en: "蔡沛淇",
    advisor: "李金威",
    address: "台北市中華路一段",
    keywords: "Propaganda ／ News ／ Media",
    motto: "邏輯",
    ingredient:
      "30%才華／30%智慧／30%努力維持心理健康與努力做對的事／10%精神補償的懶惰\n(有時候會不小心取錯成分)",
    thought: "正在學習變成更好的人",
    address_coord: "25°02'32.2\"N 121°30'30.1\"E",
    position: {
      lat: 25.042278,
      lng: 121.508361,
    },
    work: "城市的集體意識－建築宣傳論",
    avatar: "N_TSAIPeiChi_00",
  },
  {
    id: 45,
    slug: "洪晨釜",
    name_zh: "洪晨釜",
    name_en: "洪晨釜",
    advisor: "郭英釗",
    address: "新北市新莊區新北產業園區",
    keywords: "車站／都市規劃／ TOD",
    motto: "每一個設計都要有三個論述支撐",
    ingredient: "照片忘記鬍子",
    thought: "將自己的天馬行空，由抽象到現實",
    address_coord: "25°03'43.3\"N 121°27'32.8\"E",
    position: {
      lat: 25.062028,
      lng: 121.459111,
    },
    work: "TOD 城市藍圖—大眾運輸為導向的都市規劃",
    avatar: "N_HUNGChenFu_00",
  },
  {
    id: 46,
    slug: "吳昱廷",
    name_zh: "吳昱廷",
    name_en: "吳昱廷",
    advisor: "陳世堂",
    address: "台北市 中華路",
    keywords: "Industry ／ Living",
    motto:
      "大..麻煩，注音ㄉㄚˋㄇㄚˊㄈㄢˊ漢語拼音 dà má fán 釋義 無數令人煩心困擾的事",
    ingredient: "1/3酒精1/3茶1/3熱忱",
    thought: "謝謝你，咖啡因。",
    address_coord: "25°02'32.2\"N 121°30'30.1\"E",
    position: {
      lat: 25.042278,
      lng: 121.508361,
    },
    work: "植入都市載體",
    avatar: "N_WUYuTin_00",
  },
  {
    id: 47,
    slug: "鄭翔文",
    name_zh: "鄭翔文",
    name_en: "鄭翔文",
    advisor: "蔡孟廷",
    address: "新北市蘆洲區中正路",
    keywords: "手工車／垂直產業鏈／木構造",
    motto: "設計做起來/車子買起來/身子動起來!",
    ingredient: "給我的心一點機油",
    thought: "好想邊騎車邊做設計呀",
    address_coord: "25°05'20.5\"N 121°27'57.5\"E",
    position: {
      lat: 25.089028,
      lng: 121.465972,
    },
    work: "手工車產業園區",
    avatar: "O_CHENGHsiangWen_00",
  },
  {
    id: 48,
    slug: "吳振豪",
    name_zh: "吳振豪",
    name_en: "吳振豪",
    advisor: "邱韻祥",
    address: "關山親水公園",
    keywords: "關山／親水公園／原民文化",
    motto: "哪時候評完圖~",
    ingredient: "油+油+滿滿的油 (*@*)",
    thought: "翻轉，翻轉，再翻轉!",
    address_coord: "23°02'26.1\"N 121°10'27.3\"E",
    position: {
      lat: 23.040583,
      lng: 121.17425,
    },
    work: "起點。翻轉 關山場域渡假村設計規劃",
    avatar: "O_WUZhenHou_00",
  },
  {
    id: 49,
    slug: "許悅心",
    name_zh: "許悅心",
    name_en: "許悅心",
    advisor: "陳嘉芸",
    address: "嘉義縣北勢聚落",
    keywords: "新建及老屋改造／象徵場所轉化",
    motto: "一定要選自己喜歡的，祝大家玩得開心>w<",
    ingredient: "每15分鐘存一次檔，畢設前先清電腦",
    thought: "農村聚落中的一幕幕映像，深植心中",
    address_coord: "23°32'04.5\"N 120°27'18.7\"E",
    position: {
      lat: 23.534583,
      lng: 120.455194,
    },
    work: "穀作映像",
    avatar: "O_HSUYueSin_00",
  },
  {
    id: 50,
    slug: "黃歆雅",
    name_zh: "黃歆雅",
    name_en: "黃歆雅",
    advisor: "蔡孟廷",
    address: "台北兒童育樂中心舊址",
    keywords: "兒童發展／文化／傳統工藝/ 園區規劃",
    motto: "人在空間的互動性",
    ingredient: "60%紅茶+39%鮮奶+1%靈魂",
    thought: "畢業了去哪呢?",
    address_coord: "25°04'30.2\"N 121°31'20.5\"E",
    position: {
      lat: 25.075056,
      lng: 121.522361,
    },
    work: "記憶中的台北兒童育樂中心",
    avatar: "O_HUANGXinYa_00",
  },
];
